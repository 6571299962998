<template>
  <v-app class="my-application">
    <Appbar v-if="$route.path != '/delete_account'"></Appbar>
    <!-- {{ $store.state.base.mobileLang.code }} -->

    <v-main>
      <PageLoader v-if="loader"></PageLoader>
      <router-view :key="$route.fullPath"></router-view>

      <v-snackbar v-model="snackbar.open" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn dense text icon x-small v-bind="attrs" @click="closeSnackbar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>

    <Footer v-if="$route.path == '/'" />
  </v-app>
</template>

<script>
import PageLoader from "./components/PageLoader.vue";
import Appbar from "./components/Appbar.vue";
import Footer from "./components/Footer.vue";
import { Device } from "@capacitor/device";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { SafeArea } from "capacitor-plugin-safe-area";

import "leaflet/dist/leaflet.css";
import axios from "axios";

export default {
  components: {
    Appbar,
    PageLoader,
    Footer,
  },

  async created() {
    try {
      console.log(this.$route.path, "router path");
      this.setCountries();

      if (this.$store.state.base.mobileLang == null) {
        const code = await Device.getLanguageCode();
        this.setMobileLang(code.value.split("-")[0]);
        this.$i18n.locale = code.value.split("-")[0];
      } else {
        axios.defaults.headers.common[
          "Accept-Language"
        ] = `${this.mobileLang.code}`;
        this.$i18n.locale = this.mobileLang.code;
      }
      await this.isLanguageSupported();

      // BackgroundGeolocation.onLocation(
      //   (location) => {
      //     console.log("[onLocation] success: ", location);
      //     this.getLocation(location);
      //   },
      //   (error) => {
      //     console.log("[onLocation] ERROR: ", error);
      //   }
      // );

      this.toggleSnackbar({
        open: false,
        text: null,
        color: null,
      });

      if (this.isLoggedIn) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
        await this.getFavorites();
      }
      this.loader = false;

      axios.interceptors.response.use(
        (res) => {
          return res;
        },
        (err) => {
          if (err.response.status === 401) {
            this.logout().then(() => {
              this.$router.push({ path: "/login" });
            });
          }
          throw err;
        }
      );
      // App.addListener("backButton", this.$router.go(-1));
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      batteryLevel: null,
      eventLocation: null,
      location: null,
      loader: true,
    };
  },

  computed: {
    ...mapState({
      mobileLang: (state) => state.base.mobileLang,
      disclaimer: (state) => state.base.disclaimer,
      token: (state) => state.auth.token,
    }),

    ...mapGetters(["isLoggedIn"]),

    snackbar: {
      get() {
        return this.$store.state.base.snackbar;
      },
      set(value) {
        this.toggleSnackbar(value);
      },
    },
  },

  methods: {
    ...mapMutations([
      "setMobileLang",
      "toggleSnackbar",
      "setInsets",
      "setCountries",
    ]),
    ...mapActions(["getLocation", "isLanguageSupported", "getFavorites"]),

    closeSnackbar() {
      this.toggleSnackbar({
        open: false,
        text: null,
        color: null,
      });
    },
  },
};
</script>

<style lang="scss">
//======VUETIFY OVERRIDES========

.v-main {
  padding: 100px 0 !important;
}

.v-application a {
  color: #1b4a5e !important;
}
.v-main__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 4vw;
}

@media only screen and (max-width: 600px) {
  .v-main__wrap {
    display: flex;
    padding: 5px 10px;
  }
}

.v-toolbar__content {
  padding: 10px !important;
  justify-content: space-between !important;
  flex-grow: 1;
}

// .v-text-field--outlined fieldset {
//   border-color: #e9ecef !important;
//   border-radius: 1px !important;

//   transition: border-color 0.15s ease-in-out !important;
// }
// .v-input--is-focused fieldset {
//   border: 1px solid #ccc !important;
// }

// .v-input--selection-controls {
//   margin-top: 0px !important;
//   padding: 0px !important;
// }

.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 25px !important;
} //search input height

.v-icon.notranslate.mdi.mdi-circle-outline.theme--light {
  color: white !important;
} //checkbox color when unchecked

//======GLOBAL STYLES=======

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
$font-family: "Open Sans", sans-serif;
.my-application {
  *:not(.v-icon),
  *:not(.v-icon):before,
  *:not(.v-icon):after {
    font-family: $font-family !important;
  }

  //transition styles
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
